import React from 'react'
import { Link } from 'react-router-dom'

export const Button = (props) => {
  return (
    <button className={(`${props.variant?`${props.light && '!text-black'} text-[#fff] hover:text-[#071439]`:'bg-[#054CD1] text-white-10 hover:text-[#fff]'}  px-7 py-4 rounded-lg hover:bg-secondary-10 + ${props.className}`)}>
    {props.text}
    </button>
  )
}
export const LinksToPage = (props) => {
  return (
    <div className={props.divClassName}>
    <Link to={props.link} className={(`bg-[#054CD1] text-[#071439] px-7 py-4 rounded-lg hover:bg-secondary-10 + ${props.className}`)}>
    {props.text}
    </Link>

    </div>
  )
}

export const DefaultLinks = (props) => {
  return (
    <a className={(`${props.variant?`${props.light && '!text-black'} text-[#fff] hover:text-[#071439]`:'bg-[#054CD1] text-white-10 hover:text-[#fff]'}  px-7 py-4 rounded-lg hover:bg-secondary-10 + ${props.className}`)} href={props.href} >
    {props.text}
    </a>
  )
}
