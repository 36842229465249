import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar';
import '../Assets/styles/global.css'
import {DefaultLinks, LinksToPage } from '../Components/Button';
import {Button } from '../Components/Button';
import Footer from '../Components/Footer';
import DefaultInput from '../Components/DefaultInput';
import { useHistory } from 'react-router-dom';

const Contact = () => {
  // const history = useHistory();
  const services=['Personal Banking','Funds Transfer','Business Banking','Virtual Account','POS','Other']
  const [state, setstate] = useState({recipient:'help@buildbankng.com',subject:'',message:'',submittingError:false,errMessage:'',isSubmitting:false,name:'',email:'',number:''})
  const [isChecked, setisChecked] = useState('')

  const {recipient,subject,message,name,email,number}=state

  // useEffect(() => {
  //   window.location.reload(true);
  //   // Reset state or take other actions when the component mounts
  // }, []);
  const handleCheckboxChange = (value) => {
    setstate((prevState)=>({
      ...prevState,
      subject:value
    }));
    setisChecked(value)
  };
  const handleChange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
      submittingError: false,
    });
  };

  const emailMessage=`Good day, here are my details : name: ${name}, Phone Number: ${number}. ${message}`
  function onSubmit() {

    return `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailMessage)}`
  }

  console.log(state,'the contact form')
  return (
    <div >
        <Navbar logo='https://res.cloudinary.com/dewopisee/image/upload/v1702537084/Asset_10_ftqwii.svg' backgroundPageColour={true}/>


        <div className='grid md:grid-cols-2 md:flex-row gap-[30px] border-t-[1px] items-center'>
          <div className="rounded-md">
            <img src="https://res.cloudinary.com/dewopisee/image/upload/v1702722137/Image_fyl5ba.svg" alt=" "  className='rounded-md'/>
          </div>
            <div className='w-full grid gap-[15px] px-10'>
              <div className="grid gap-[20px]">
                <p className="text-[46px] font-bold font-[#101828]">Talk to us</p>
                <p className="text-[20px]">You can reach us anytime via <span className="text-[#3477E4]">help@buildbankng.com</span> </p>
              </div>
            <DefaultInput label='Name' placeHolder='Your name' value={name} name='name' handleChange={handleChange}/>
            <DefaultInput label='Email' placeHolder='you@company.com' value={email} name='email' handleChange={handleChange}/>
                <DefaultInput label='Phone number' placeHolder='+1 (555) 000-0000' value={number} name='number' handleChange={handleChange}/>
                
                <div className="w-full grid gap-1">
                  <p className="text-[#344054]">How can we help?</p>
                <textarea className='border w-full border-white-50 rounded-lg placeholder:text-white-50 text-black p-[1em]' placeholder='Tell us a little about the project...' name="message" id="" cols="20" rows="5" value={message} onChange={handleChange}></textarea>
                </div>

                <div className="grid gap-[20px]">
                  <p className="text-[#344054] font-bold">Services</p>
                  <div className="grid grid-cols-2 gap-[15px]">
                    {services.map((val)=>(

                    <div className="flex gap-2">
                      <input type="checkbox" name="" id="" onChange={() => handleCheckboxChange(val)} checked={isChecked===val}/>
                      <p className="text-[#344054] text-[16px]">{val}</p>
                    </div>
                    ))}
                  </div>
                </div>

                    <DefaultLinks text='Send Message' href={`mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailMessage)}`} className='text-center'/>
                    
                   
            </div>
        </div>


        <Footer/>
    </div>
  )
}

export default Contact