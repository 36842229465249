import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import "../Assets/styles/global.css";
import { Button, LinksToPage } from "../Components/Button";
import Footer from "../Components/Footer";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa";
import { IoIosArrowDropright } from "react-icons/io";

const AboutUs = () => {
  return (
    <div>
      <div className="min-h-screen">
        <Navbar
          logo="https://res.cloudinary.com/dewopisee/image/upload/v1702537084/Asset_10_ftqwii.svg"
          backgroundPageColour={true}
        />

        <header className="px-[20px] lg:px-[140px] md:my-[80px]">
          <div className=" md:w-3/4 md:m-auto text-center grid gap-[50px] w-full ">
            <p className="text-[3rem] md:text-[4rem] text-[#071B7B] font-bold">
              Empower Your <span className="text-[#F1C643] m-o">Business</span>{" "}
              with Seamless{" "}
              <span className="text-[#F1C643] m-o">Banking Solutions</span>
            </p>
            <p className="text-[#5A5757] text-[14px] mb-[80px]">
              Unlock the Full Potential of Your Business with Our Intuitive
              Banking Tools
            </p>
          </div>

          <img
            src="https://res.cloudinary.com/dewopisee/image/upload/v1705917941/IMG_1404_1_wwhutj.png"
            alt="header"
            className="m-auto"
          />
        </header>
      </div>

      <div className="px-[20px] lg:px-[140px] grid gap-[30px] md:grid-cols-[3fr_1fr]">
        <div className="grid md:border-r-[1px] border-r-[#5A5757] gap-[30px] text-center md:text-left lg:pr-[80px]">
          <div className="grid gap-1 ">
            <p className="text-[#1D2939] text-[60px]">Who we are </p>
            <p className="text-[#5A5757] text-[20px]">
              Build bank is a modern digital bank which makes use of technology
              to provide access to tools that support small/medium scale
              businesses and individuals{" "}
            </p>
          </div>

          <div className="grid gap-1">
            <p className="text-[#1D2939] text-[60px]">Our Promise </p>
            <p className="text-[#5A5757] text-[20px]">
              To offer a solid and user-friendly platform of financial tools
              that support the development of both businesses and individuals.{" "}
            </p>
          </div>
        </div>

        <div className="text-center md:text-left grid md:justify-items-stretch md:m-auto gap-4">
          <div className="grid h-fit">
            <p className="text-[#1D2939] text-[60px]">2022</p>
            <p className="text-[#5A5757] text-[12px]">founded</p>
          </div>
          <div className="grid h-fit">
            <p className="text-[#1D2939] text-[60px]">100M </p>
            <p className="text-[#5A5757] text-[12px]">Transactions processed</p>
          </div>
          <div className="grid h-fit">
            <p className="text-[#1D2939] text-[60px]">10k</p>
            <p className="text-[#5A5757] text-[12px]">Business</p>
          </div>
        </div>
      </div>

      <div className="grid px-[2em] md:py-[5em] py-[3em]  bg-white-10   m-auto lg:px-[10em] ">
        <div className="max-w-[80em] m-auto grid gap-[2em] justify-between items-center">
          <p className=" text-secondary-10 text-[40px] text-center md:text-left">
            Unlock your <span className="font-bold">banking dreams</span>
          </p>
          <p className="text-[14px] text-[#667085] lg:w-3/5 text-center md:text-left">
            Where your financial aspirations come to life! Our platform is
            dedicated to empowering you on your journey toward financial
            success.
          </p>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="rounded-[40px] h-fit bg-[#EBF1FC] py-[35px] px-[35px] relative grid justify-center items-center">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1705934318/saturn-1000_2_khy9ot.png"
                alt=""
              />

              <div className="absolute bottom-0 mb-[22px] bg-[#ffff] w-[calc(100%-20px)] mx-[10px] shadow-lg rounded-lg h-fit p-[20px] gap-2">
                <p className="text-[#3477E4] text-[14px]">Build Bank Mini</p>
                <p className="text-[#656C75] text-[10px]">
                It offers portability and enhanced functionality.  
                </p>
              </div>
            </div>
            <div className="rounded-[40px] h-fit bg-[#EBF1FC]  relative grid justify-center items-center">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1705935178/Group_2_1_oqrujn.png"
                alt=""
                className=" w-full object-fill"
              />

              <div className="absolute bottom-0 mb-[22px] bg-[#ffff] w-[calc(100%-20px)] mx-[10px] shadow-lg rounded-lg h-fit p-[20px] gap-2">
                <p className="text-[#3477E4] text-[14px]">Personal/Business Banking </p>
                <p className="text-[#656C75] text-[10px]">
                  Durability beyond your typical banking solutions. 
                </p>
              </div>
            </div>
            <div className="rounded-[40px] h-full bg-[#EBF1FC] py-[35px] px-[35px] relative grid justify-center items-center">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1705936189/saturn-1000_1_dtcnon.png"
                alt=""
              />

              <div className="absolute bottom-0 mb-[22px] bg-[#ffff] w-[calc(100%-20px)] mx-[10px] shadow-lg rounded-lg h-fit p-[20px] gap-2">
                <p className="text-[#3477E4] text-[14px]">Build Bank Maxi</p>
                <p className="text-[#656C75] text-[10px]">
                  Versatile and seamlessly robust, it fulfills diverse needs. 
                </p>
              </div>
            </div>
           
          </div>
          <div></div>
        </div>
      </div>
      <div className="mb-[120px] text-center m-auto grid gap-[60px] px-[20px] md:w-1/2 lg:w-1/3">
        <p className="text-[#1D2939] font-bold text-[40px]">Join our team</p>
        <p className="text-[#667085] text-[14px]">
          Join our diverse team in our mission to simplify financial
          transactions for businesses across Africa and beyond. Your
          contribution matters as we collectively shape the future of fintech
          innovation.{" "}
        </p>

        <div className="w-fit m-auto">
          <Button text="View current openings" />
        </div>
      </div>

      <div
        className=" bg-[#071439]  px-[2em] lg:px-[10em] pt-[5em] md:pt-0  md:pb-0 w-screen  "
        // style={{
        //   backgroundImage: `url(https://res.cloudinary.com/dewopisee/image/upload/v1682595661/Frame_jltwna.png)`,
        //   backgroundSize: "cover",
        // }}
      >
        <div className="max-w-[80em] m-auto flex flex-col md:flex-row justify-between items-center">
          <div className="md:w-1/2 lg:w-1/3 grid justify-between space-y-5 pb-[3em] md:pb-0">
            <h1 className="h2 text-white-10">
              Get <span className="text-yellow ">better</span> with Money
            </h1>
            <p className="p2 text-white-10">
              Download the app to manage your finances, keep track of your
              business branches, confirm payments and bank on the move.
            </p>
            <div className="mt-5 grid">
              <p className=" text-white-10 !font-bold">Get the App</p>

              <div className="flex gap-5 mt-2">
                <img
                  src="https://res.cloudinary.com/dewopisee/image/upload/v1702546046/artwork_gubtpr.svg"
                  alt=""
                />
                <img
                  src="https://res.cloudinary.com/dewopisee/image/upload/v1702546104/Group_z1a4zn.svg"
                  alt=""
                />
              </div>
            </div>
            {/* <Link to="/contact" className="flex items-center space-x-3">
              Contact Us <IoIosArrowDropright />{" "}
            </Link> */}
          </div>
          <div className="justify-end items-end grid md:m-0">
            <img
              src="https://res.cloudinary.com/dewopisee/image/upload/v1702542310/Group_2_lrvtah.svg"
              alt="man that is annoucing"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
