import React, { useState } from "react";
import Navbar from "./Navbar";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa";
import { IoIosArrowDropright } from "react-icons/io";
import "../Assets/styles/global.css";
import { LinksToPage } from "./Button";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Typed from "react-typed";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { FaArrowRight } from "react-icons/fa";

const Homepage = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };
  const flavor = [
    {
      text: "Digital Banking",
      image:
        "https://res.cloudinary.com/dewopisee/image/upload/v1681828239/Icon-17_hgalpu.png",
    },
    {
      text: "Savings",
      image:
        "https://res.cloudinary.com/dewopisee/image/upload/v1681828319/Icon-8_y1lcz7.png",
    },
    {
      text: "Payments & Collections",
      image:
        "https://res.cloudinary.com/dewopisee/image/upload/v1681828353/Icon-14_jxmleb.png",
    },
    {
      text: "Business Banking",
      image:
        "https://res.cloudinary.com/dewopisee/image/upload/v1681828416/Icon-15_h5eh5j.png",
    },
    {
      text: "Business Investments",
      image:
        "https://res.cloudinary.com/dewopisee/image/upload/v1681828459/Icon-16_uuadlo.png",
    },
    {
      text: "Loans",
      image:
        "https://res.cloudinary.com/dewopisee/image/upload/v1681828494/Icon-13_flhywx.png",
    },
  ];

  return (
    <div className="bg-white-40">
      <div className="w-screen bg-[#071439] min-h-screen flex flex-col justify-between">
        <Navbar
          className="text-white-10"
          logo="https://res.cloudinary.com/dewopisee/image/upload/v1702367143/Layer_1_cur9sr.svg"
        />
        <header className="grid md:grid-cols-2 mt-[3em]  text-[#ffff]  2xl:max-w-[100em] justify-between  h-[100%]">
          <div className="">
            <div className="grid px-[2em] lg:px-[5em]">
              <p className="text-[#5BC6F2] text-[1em]">
                Super. Simple. Banking.
              </p>
              <p className=" font-bold text-[3em] lg:text-[4em]">
                <span className="text-[#F1C643]">Banking</span> <br />
                You Want To Use
              </p>
            </div>
            <div className="bg-[#F1C643] mt-10 w-full h-[30em] md:h-[20em] rounded-tr-[7em] relative ">
              <div className=" w-full absolute top-[-6%]">
                <div className=" rounded-lg w-fit p-[1em] bg-opacity-[59%] bg-[#071B7B] text-[#fff] mx-auto">
                  <p>Join 500+ Businesses on Build Bank</p>
                </div>
              </div>
              <div className="absolute h-full w-[calc(100vw-4em)] md:w-[calc(100vw-10em)]">
                <div className="m-auto grid items-center  h-full">
                  <div className="bg-[#fff] relative mx-[2em] lg:mx-[5em] rounded-2xl rounded-tl-none w-full mt-20 md:mt-[10em] 2xl:max-w-[100em] ">
                    <div className="absolute rounded-2xl bg-[#fff] top-[-3.5em] rounded-bl-none ">
                      <p className="text-[#000] p-[1em] text-[17px]">
                        Our Advantages
                      </p>
                    </div>
                    <div className=" py-[2em] grid md:flex w-full">
                      <div className="p-[1em] md:border-r-[1px] border-r-[#656c75] ">
                        <p className="text-[#656c75]">
                          Quick setup. Begin accepting payments in{" "}
                          <span className="text-[#000] font-semibold">
                            {" "}
                            15 minutes.
                          </span>
                        </p>
                      </div>
                      <div className="p-[1em] md:border-r-[1px] border-r-[#656c75] ">
                        <p className="text-[#656c75]">
                          Honest pricing. Only pay for{" "}
                          <span className="text-[#000] font-semibold">
                            {" "}
                            successful transactions.
                          </span>
                        </p>
                      </div>
                      <div className="p-[1em] ">
                        <p className="text-[#656c75]">
                          Best-in-class. Enjoy an exceptional payments in{" "}
                          <span className="text-[#000] font-semibold">
                            {" "}
                            payment experience.
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* <p className="text-[#000]">wow</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-fit  mt-5 md:mt-0">
            <img
              src="https://res.cloudinary.com/dewopisee/image/upload/v1702380282/Group_3_ksfopr.png"
              alt=""
              className="m-auto  md:h-[30em] "
            />
          </div>
        </header>
      </div>

      <div className="grid px-[2em] md:py-[5em] py-[3em]  bg-white-10   m-auto lg:px-[10em] ">
        <div className="max-w-[80em] m-auto grid gap-[2em] justify-between items-center">
          <p className=" text-secondary-10 text-[40px] text-center md:text-left">
            Unlock your <span className="font-bold">banking dreams</span>
          </p>
          <p className="text-[14px] text-[#667085] lg:w-3/5 text-center md:text-left">
            Where your financial aspirations come to life! Our platform is
            dedicated to empowering you on your journey toward financial
            success.
          </p>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="rounded-[40px] h-fit bg-[#EBF1FC] py-[35px] px-[35px] relative grid justify-center items-center">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1705934318/saturn-1000_2_khy9ot.png"
                alt=""
              />

              <div className="absolute bottom-0 mb-[22px] bg-[#ffff] w-[calc(100%-20px)] mx-[10px] shadow-lg rounded-lg h-fit p-[20px] gap-2">
                <p className="text-[#3477E4] text-[14px]">Build Bank Mini</p>
                <p className="text-[#656C75] text-[10px]">
                It offers portability and enhanced functionality.  
                </p>
              </div>
            </div>
            <div className="rounded-[40px] h-fit bg-[#EBF1FC]  relative grid justify-center items-center">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1705935178/Group_2_1_oqrujn.png"
                alt=""
                className=" w-full object-fill"
              />

              <div className="absolute bottom-0 mb-[22px] bg-[#ffff] w-[calc(100%-20px)] mx-[10px] shadow-lg rounded-lg h-fit p-[20px] gap-2">
                <p className="text-[#3477E4] text-[14px]">Personal/Business Banking </p>
                <p className="text-[#656C75] text-[10px]">
                  Durability beyond your typical banking solutions. 
                </p>
              </div>
            </div>
            <div className="rounded-[40px] h-full bg-[#EBF1FC] py-[35px] px-[35px] relative grid justify-center items-center">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1705936189/saturn-1000_1_dtcnon.png"
                alt=""
              />

              <div className="absolute bottom-0 mb-[22px] bg-[#ffff] w-[calc(100%-20px)] mx-[10px] shadow-lg rounded-lg h-fit p-[20px] gap-2">
                <p className="text-[#3477E4] text-[14px]">Build Bank Maxi</p>
                <p className="text-[#656C75] text-[10px]">
                  Versatile and seamlessly robust, it fulfills diverse needs. 
                </p>
              </div>
            </div>
           
          </div>
          <div></div>
        </div>
      </div>

      <div className="grid p-[2em] py-[3em] md:py-[5em] lg:px-[10em] bg-[#3477E41A] bg-opacity-[10%]  m-auto ">
        <div className="grid m-auto max-w-[100em] ">
          <div className=" lg:w-3/4 grid gap-[3em]">
            <p className="bold text-[17px] bg-white-40 rounded-md p-[10px] w-fit">We have moved our office from ANAMBRA to LAGOS</p>
            <p className=" text-[30px] md:text-[40px] md:w-3/4">
            All essential tools for{" "}
              <span className="font-bold"> running your business seamlessly.</span>
            </p>

            <p className="text-[#667085]">
            Boundless payment opportunities for enterprises, individuals, small businesses, emerging markets, and startups with our innovative solutions.  

 
            </p>

            <div className="flex flex-wrap gap-[20px]">
              <div className="border rounded-md p-[20px] text-black border-primary-300 bg-white-10">
                <p className="">Funds Transfer</p>
              </div>
              <div className="border rounded-md p-[20px] text-black border-primary-300">
                <p className="">Virtual Account Service</p>
              </div>
              <div className="border rounded-md p-[20px] text-black border-primary-300">
                <p className="">Airtime / Data</p>
              </div>
              <div className="border rounded-md p-[20px] text-black border-primary-300">
                <p className="">Bill Payment</p>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 mt-10 gap-[20px] items-center justify-between">
            <div className="grid md:flex gap-[10px] items-center">
            <img
                    src="https://res.cloudinary.com/dewopisee/image/upload/v1720610217/john-schnobrich-FlPc9_VocJ4-unsplash_coiwkd.jpg"
                    alt=""
                    className="m-auto w-[75%]"
                  />
              {/* <div className="w-full h-[30em] rounded-sm bg-[#071439] grid m-auto ">
                <div className="border-[#5BC6F2] m-auto border-[2px] w-[96px] h-[96px] grid justify-center">
                  
                </div>
              </div> */}
              <div className="flex md:grid gap-[10px]">
                <div className="border-[2px] border-[#5BC6F2] w-full md:w-[96px] h-[96px] grid justify-center">
                  <img
                    src="https://res.cloudinary.com/dewopisee/image/upload/v1702672839/Vector-2_boat8q.svg"
                    alt=" m-auto"
                    className="m-auto"
                  />
                </div>
                <div className="border-[2px] border-[#5BC6F2] w-full md:w-[96px] h-[96px] grid justify-center">
                  <img
                    src="https://res.cloudinary.com/dewopisee/image/upload/v1702672839/Vector-2_boat8q.svg"
                    alt=" m-auto"
                    className="m-auto"
                  />
                </div>
                <div className="border-[2px] border-[#5BC6F2] w-full md:w-[96px] h-[96px] grid justify-center">
                  <img
                    src="https://res.cloudinary.com/dewopisee/image/upload/v1702672839/Vector-2_boat8q.svg"
                    alt=" m-auto"
                    className="m-auto"
                  />
                </div>
              </div>
            </div>
            <div className="grid gap-[20px] ">
              <p className="text-[30px] text-[#1D2939]">Funds Transfer</p>
              <p className="text-[#667085]">
              Swift and instant, send money with a guaranteed 100% success rate to all banks in Nigeria. Trust that your transactions are secure and dependable. 

 
              </p>

              <p className="text-[#3477E4] flex items-center gap-3">
                Interested in funds transfer ? <FaArrowRight className="m-0" />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="max-w-[80em] m-auto ">
        <div
          className="flex flex-col md:h-fit-content space-y-[5em] p-[2em] lg:p-[6em] md:rounded-[3em] md:mx-[2em] lg:mx-[5em] my-[2em] justify-between bg-secondary-10 items-center  "
          style={{
            backgroundImage: `url(https://res.cloudinary.com/dewopisee/image/upload/v1682595564/BGCTA_azepjm.png)`,
            backgroundSize: "cover",
          }}
        >
          <div className="md:w-[60%] flex flex-col justify-center items-center m-auto gap-[2em]">
            <h1 className="h2 text-center text-white-10">
              A Bank after your own heart
            </h1>
            <p className="text-white-30 text-center">
              Get started and create an account in minutes
            </p>
          </div>
          <div className=" grid justify-center">
            <LinksToPage link="/comingSoon" text="Get Started" />
          </div>
        </div>
      </div> */}

      <div className="grid max-w-[100em]  px-[2em] lg:px-[10em] m-auto gap-20 md:grid-cols-2 py-[5em]">
        <div className="grid gap-5 h-fit ">
          <p className="text-[#054CD1] font-bold">Support</p>
          <h1 className="text-[#101828] font-semibold text-[36px]">FAQs</h1>
          <p className="text-black">
            Everything you need to know about the product and getting started.
            Can’t find the answer you’re looking for? Please chat to our
            friendly team.
          </p>
        </div>

        <div className="mt-[1em] space-y-2">
          <Accordion
            open={open === 1}
            animate={customAnimation}
            className="space-y-2 px-5 py-1 rounded-sm"
          >
            <AccordionHeader onClick={() => handleOpen(1)}>
              <p className="p3 text-secondary-10">
                Who can sign up on Build Bank
              </p>
            </AccordionHeader>

            <AccordionBody>
              <p>
              BuildBank offers a fast signup process for individuals and businesses with valid ID, ensuring security and efficiency.
              </p>
            </AccordionBody>
          </Accordion>

          <Accordion
            open={open === 2}
            animate={customAnimation}
            className="space-y-2 px-5 py-1 rounded-sm"
          >
            <AccordionHeader onClick={() => handleOpen(2)}>
              <p className="p3 text-secondary-10">
              How long does it take to get a POS terminal?  
              </p>
            </AccordionHeader>

            <AccordionBody>
              <p>
              After you make a request, you can get the POS terminal within 24hrs 
              </p>
            </AccordionBody>
          </Accordion>

          <Accordion
            open={open === 3}
            animate={customAnimation}
            className="space-y-2 px-5 py-1 rounded-sm"
          >
            <AccordionHeader onClick={() => handleOpen(3)}>
              <p className="p3 text-secondary-10">
              What cards does the POS terminal accept? 
              </p>
            </AccordionHeader>

            <AccordionBody>
              <p>
              Your POS terminal is compatible with all cards. 
              </p>
            </AccordionBody>
          </Accordion>

          <Accordion
            open={open === 4}
            animate={customAnimation}
            className="space-y-2 px-5 py-1 rounded-sm"
          >
            <AccordionHeader onClick={() => handleOpen(4)}>
              <p className="p3 text-secondary-10 text-left">
              What documents are required to complete my registration? 
              </p>
            </AccordionHeader>

            <AccordionBody>
              <ul className="">
                <li>Your Bank Verification Number (BVN).</li>
                <li>A utility or another bill dated no later than three months prior to merchant registration as proof of your address. </li>
                <li>Copy of your national ID card, driver’s license, or international passport.</li>
                <li>1 passport photo.</li>
              </ul>
            </AccordionBody>
          </Accordion>

          <Accordion
            open={open === 5}
            animate={customAnimation}
            className="space-y-2 px-5 py-1 rounded-sm"
          >
            <AccordionHeader onClick={() => handleOpen(5)}>
              <p className="p3 text-secondary-10 text-left w-3/4">
              Can I proceed with registration if I do not have the Corporate Affairs Commission (CAC) or business name documents? 
              </p>
            </AccordionHeader>

            <AccordionBody>
              <p>
              Yes, you can proceed to register as our merchant without this document 
              </p>
            </AccordionBody>
          </Accordion>
        </div>
      </div>

      <div
        className=" bg-[#071439]  px-[2em] lg:px-[10em] pt-[5em] md:pt-0  md:pb-0 w-screen  "
        // style={{
        //   backgroundImage: `url(https://res.cloudinary.com/dewopisee/image/upload/v1682595661/Frame_jltwna.png)`,
        //   backgroundSize: "cover",
        // }}
      >
        <div className="max-w-[80em] m-auto flex flex-col md:flex-row justify-between items-center">
          <div className="md:w-1/2 lg:w-1/3 grid justify-between space-y-5 pb-[3em] md:pb-0">
            <h1 className="h2 text-white-10">
              Get <span className="text-yellow ">better</span> with Money
            </h1>
            <p className="p2 text-white-10">
              Download the app to manage your finances, keep track of your
              business branches, confirm payments and bank on the move.
            </p>
            <div className="mt-5 grid">
              <p className=" text-white-10 !font-bold">Get the App</p>

              <div className="flex gap-5 mt-2">
                <img
                  src="https://res.cloudinary.com/dewopisee/image/upload/v1702546046/artwork_gubtpr.svg"
                  alt=""
                />
                <img
                  src="https://res.cloudinary.com/dewopisee/image/upload/v1702546104/Group_z1a4zn.svg"
                  alt=""
                />
              </div>
            </div>
            {/* <Link to="/contact" className="flex items-center space-x-3">
              Contact Us <IoIosArrowDropright />{" "}
            </Link> */}
          </div>
          <div className="justify-end items-end grid md:m-0">
            <img
              src="https://res.cloudinary.com/dewopisee/image/upload/v1702542310/Group_2_lrvtah.svg"
              alt="man that is annoucing"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
