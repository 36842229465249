import React, { useEffect, useState } from "react";
import "../Assets/styles/global.css";
import { Link } from "react-router-dom";
import { Button, LinksToPage } from "./Button";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Navbar = (props) => {
  const [light, setLight] = useState(false);

 
  
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(false);
  useEffect(() => {
    if(props.backgroundPageColour) setLight(true)
   }, [props.backgroundPageColour])
  return (
    <div className="">
      <div className="relative">
      <div
        className={`flex w-screen max-w-[100em] m-auto justify-between items-center py-5 lg:px-[5em] px-[2em] + ${props.className} md:gap-5 ` }
      >
        <Link to="/" className="items-center">
          <img src={props.logo} alt="logo" />
        </Link>
        <div className="hidden lg:flex justify-between lg:gap-[2em] text-sm  gap-2 ">
          <div>
            <div className="flex items-center gap-1 peer">
              <p className="peer">Home</p>
              <div className="peer-hover:hidden block border-red">
                <IoIosArrowDown />
              </div>
              <div className="peer-hover:block hidden border-green">
                <IoIosArrowUp />
              </div>
            </div>
            <div className="bg-white-10 absolute  rounded-xl hidden peer-hover:block hover:block ">
              <div className="flex flex-col p-[3em] text-black gap-[2em]">
                <Link to="/" className="hover:text-light-blue-500">
                  Open a business account
                </Link>
                <Link to="/business" className="hover:text-light-blue-500">
                  Get a Pos
                </Link>
                <Link to="/About" className="hover:text-light-blue-500">
                  Business Loans{" "}
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1 peer">
              <p className="peer">Products</p>
              <div className="peer-hover:hidden block border-red">
                <IoIosArrowDown />
              </div>
              <div className="peer-hover:block hidden border-green">
                <IoIosArrowUp />
              </div>
            </div>
            <div className="bg-white-10 absolute  rounded-xl hidden peer-hover:block hover:block ">
              <div className="flex flex-col p-[3em] text-black gap-[2em]">
                <Link to="/" className="hover:text-light-blue-500">
                  Open account
                </Link>
                <Link to="/business" className="hover:text-light-blue-500">
                  Loans
                </Link>
                <Link to="/About" className="hover:text-light-blue-500">
                  Cards
                </Link>
                <Link to="/stories" className="hover:text-light-blue-500">
                  Vas(List all VAS)
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1 peer">
              <p className="peer">Agency Banking</p>
              <div className="peer-hover:hidden block border-red">
                <IoIosArrowDown />
              </div>
              <div className="peer-hover:block hidden border-green">
                <IoIosArrowUp />
              </div>
            </div>
            <div className="bg-white-10 absolute  rounded-xl hidden peer-hover:block hover:block ">
              <div className="flex flex-col p-[3em] text-black gap-[2em]">
                <Link to="/" className="hover:text-light-blue-500">
                  Become an agent
                </Link>
                <Link to="/business" className="hover:text-light-blue-500">
                  Get a POS
                </Link>
                <Link to="/About" className="hover:text-light-blue-500">
                  Agent Loans
                </Link>
                <Link to="/stories" className="hover:text-light-blue-500">
                  VAS
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1 peer">
              <p className="peer">Resources</p>
              <div className="peer-hover:hidden block border-red">
                <IoIosArrowDown />
              </div>
              <div className="peer-hover:block hidden border-green">
                <IoIosArrowUp />
              </div>
            </div>
            <div className="bg-white-10 absolute  rounded-xl hidden peer-hover:block hover:block ">
              <div className="flex flex-col p-[3em] text-black gap-[2em]">
                <Link to="/" className="hover:text-light-blue-500">
                  Documentation
                </Link>
                <Link to="/business" className="hover:text-light-blue-500">
                  Integration
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1 peer">
              <p className="peer">Pricing</p>
              <div className="peer-hover:hidden block border-red">
                <IoIosArrowDown />
              </div>
              <div className="peer-hover:block hidden border-green">
                <IoIosArrowUp />
              </div>
            </div>
            <div className="bg-white-10 absolute  rounded-xl hidden peer-hover:block hover:block ">
              <div className="flex flex-col p-[3em] text-black gap-[2em]">
                <Link to="/" className="hover:text-light-blue-500">
                  Company
                </Link>
                <Link to="/business" className="hover:text-light-blue-500">
                  Business
                </Link>
                <Link to="/About" className="hover:text-light-blue-500">
                  About Us
                </Link>
                <Link to="/stories" className="hover:text-light-blue-500">
                  Blog
                </Link>
                <Link to="/FAQs" className="hover:text-light-blue-500">
                  FAQs
                </Link>
              </div>
            </div>
          </div>
        </div>

       
<div className="fixed">

</div>
        {open ? (
          <div className="lg:hidden">
            <div
              className="absolute"
              onClick={() => setOpen(!open)}
            ><div className="fixed h-screen w-screen top-0 left-0 bg-[#000000] bg-opacity-[60%] z-30"></div></div>
            <div className="  absolute z-40">
              <div className="pb-[2em] w-2/3 bg-white-10 top-0 right-0 rounded-2xl z-100 fixed">
              <div className="mt-[2em] grid justify-end items-end mr-[2em] ">
                <AiOutlineClose
                  size="30px"
                  color="black"
                  onClick={() => setOpen(!open)}
                />
              </div>
              <div className="h-screen flex flex-col h4 text-secondary-10 text:hover-primary-50 space-y-5 px-[2em] pt-[2em]">
                <Link to="/business">Business</Link>
                <Link to="/">Company</Link>
                <Link to="/About">About Us</Link>
                <Link to="/stories">Blog</Link>
                <Link to="/FAQs">FAQs</Link>
                <div className="flex justify-between items-center ">
                  <a href="#">
                    <FaFacebookF color="blue" size="1.5em" />
                  </a>
                  <a href="#">
                    <FaInstagram color="blue" size="1.5em" />
                  </a>
                  <a href="#">
                    <FaTwitter color="blue" size="1.5em" />
                  </a>
                  <a href="#">
                    <FaLinkedin color="blue" size="1.5em" />
                  </a>
                </div>
              </div>

              </div>
            </div>
          </div>
        ) : null}
<div className="md:flex gap-2 items-center">
  <div className="hidden gap-2 items-center md:flex">
        <Button
          text="Sign up"
          // className="md:p-2 lg:px-7 lg:py-4 text-sm min-w-[10em]"
        />
        <Button text='Login' variant={true} light={light} className={'hover:!text-white-10'}/>

  </div>
        <GiHamburgerMenu
          size="30px"
          onClick={() => setOpen(!open)}
          className="lg:hidden"
        />
      </div>

</div>

      </div>
    </div>
  );
};

export default Navbar;
