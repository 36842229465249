import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import logo from "../Assets/Images/Logo.png";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa";
import "../Assets/styles/global.css";
import { Link } from "react-router-dom";
import { FiInstagram } from "react-icons/fi";
import { FaDribbble } from "react-icons/fa";
const Footer = () => {
  return (
    <div className="flex flex-col justify-center bg-[#e8e9f2]">
      <div className="grid justify-center  gap-[3em] lg:space-y-0 md:grid-cols-2 relative z-20">
        <div className="relative z-20 m-auto md:m-0">
          <img
            src="https://res.cloudinary.com/dewopisee/image/upload/v1702479808/Vector_a7qryu.svg"
            alt=""
            className="w-[10em] md:w-full"
          />
          <div className="absolute p-[2em] lg:p-[10em] top-0  h-full">
            <div className=" h-full m-auto grid ">
              <img
                src="https://res.cloudinary.com/dewopisee/image/upload/v1702537084/Asset_10_ftqwii.svg"
                alt="logo"
                className="items-start lg:m-auto"
              />
            </div>
          </div>
        </div>
        <div className="flex p-[2em]  text-black m-auto items-center justify-center ">
          <div className="flex flex-wrap md:gap-[2em] gap-[1em] m-auto ">
            <div className="gap-[1em] grid h-fit ">
              <p className=" p3 !text-[20px] !font-semibold">Product</p>
              <ul className="text-[16px] grid gap-2  !font-semibold">
                <Link to="/About">
                  <li className=" p3 !font-semibold">Business Banking</li>
                </Link>
                <Link to="/contact">
                  <li className=" p3 !font-semibold">Person Banking</li>
                </Link>
                <Link>
                  <li className=" p3 !font-semibold">POS</li>
                </Link>
                <Link to="/stories">
                  <li className=" p3 !font-semibold">Pricing</li>
                </Link>
              </ul>
            </div>
            <div className="gap-[1em] grid h-fit ">
              <p className=" p3 !text-[20px] !font-semibold">Company</p>
              <ul className="text-[16px] grid gap-2  !font-semibold">
                <Link to="/About">
                  <li className=" p3 !font-semibold">About Us</li>
                </Link>
                <Link to="/contact">
                  <li className=" p3 !font-semibold">Contact</li>
                </Link>
                <Link>
                  <li className=" p3 !font-semibold">Partners</li>
                </Link>
                <Link to="/stories">
                  <li className=" p3 !font-semibold">Blog</li>
                </Link>
              </ul>
            </div>
            <div className="gap-[1em] grid h-fit items-start ">
              <p className=" p3 !text-[20px] !font-semibold">Support</p>
              <ul className="text-[16px] grid gap-2 !font-semibold">
                <Link to="/About">
                  <li className=" p3 !font-semibold">Help Center</li>
                </Link>
                <Link to="/contact">
                  <li className=" p3 !font-semibold">Terms of service</li>
                </Link>
                <Link>
                  <li className=" p3 !font-semibold">Privacy Policy</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="absolute bottom-[-100px] lg:bottom-[-50px] w-full bg-[#d1d5e4] h-[7em] flex flex-wrap items-center md:justify-between px-[2em] lg:px-[10em] justify-center z-10 flex-col-reverse md:flex-row gap-5">
          <div className="grid">
          <p className="text-black text-center">© 2023 Build Bank. All rights reserved</p>
          <p className="text-black text-center">7a idejo street, Adeola Odeku, VI, Lagos State </p>
          <p className="text-black text-center">07001239445 </p>

          </div>
          <div className="flex gap-10 text-[#fff] ">
            <div className="p-2 rounded-full m-auto bg-[#fff] bg-opacity-[30%]">
              <FiInstagram />
            </div>
            <div className="p-2 rounded-full m-auto bg-[#fff] bg-opacity-[30%]">
              <FaDribbble />
            </div>
            <div className="p-2 rounded-full m-auto bg-[#fff] bg-opacity-[30%]">
              <FaTwitter />
            </div>
            <div className="p-2 rounded-full m-auto bg-[#fff] bg-opacity-[30%]">
              <FaYoutube />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid justify-center  gap-[3em] lg:space-y-0 lg:grid-cols-4 grid-cols-2 py-[4em] px-[2em] lg:px-[10em] m-auto ">
        <div className="gap-[3em] ">
          <p className="text-secondary-10 p3">Company</p>
          <ul>
            <Link to="/About">
              <li className="text-white-30 p3">About Us</li>
            </Link>
            <Link to="/contact">
              <li className="text-white-30 p3">Contact</li>
            </Link>
            <Link>
              <li className="text-white-30 p3">Partners</li>
            </Link>
            <Link to="/stories">
              <li className="text-white-30 p3">Blog</li>
            </Link>
          </ul>
        </div>
        <div className="space-y-3">
          <p className="text-secondary-10 p3">Products</p>
          <ul>
            <Link>
              <li className="text-white-30 p3">Personal Accounts</li>
            </Link>
            <Link>
              <li className="text-white-30 p3">Build for business</li>
            </Link>
          </ul>
        </div>
        <div className="space-y-3">
          <p className="text-secondary-10 p3">Resources</p>
          <ul>
            <Link>
              <li className="text-white-30 p3">Help Center</li>
            </Link>
            <Link>
              <li className="text-white-30 p3">Privacy Policy</li>
            </Link>
            <Link to="/FAQs">
              <li className="text-white-30 p3">FAQs</li>
            </Link>
          </ul>
        </div>
        <div className="space-y-3">
          <p className="text-secondary-10 p3">Connect with us</p>
          <div>
            <p className="text-white-30 p3">
              <span className="text-secondary-10 p3">Address: </span> <br />
              7a, Idejo Street, Victoria Island, Lagos. <br />
              <span className="text-secondary-10 p3">Email:</span> <br />
              contactus@buidbankng.com
            </p>
          </div>

          <div className="flex space-x-3">
            <FaFacebookF />
            <FaInstagram />
            <FaTwitter />
            <FaLinkedinIn />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-5 md:space-y-0 lg:flex-row gap-[1em] justify-between mx-[2em] lg:mx-[10em] border-t-[3] border-white-40  py-[4em] items-center">
        <div>
          <img src='https://res.cloudinary.com/dewopisee/image/upload/v1681829690/Logo_iehiv3.png' alt="" className="m-auto md:m-0" />
        </div>
        <div className="flex space-x-7 justify-center">
          <a
            href="#"
            className="border bg-secondary-10 flex rounded-2xl p-3 items-center w-[160px] space-x-2"
          >
            <AiFillApple size="2em" color="white" />
            <p className="text-white-30">
              Get on the <br />{" "}
              <span className="text-white-10">App Store</span>{" "}
            </p>
          </a>
          <a
            href="#"
            className="border border-primary-50 bg-primary-50 flex rounded-2xl p-3 items-center w-[160px] space-x-2"
          >
            <FaGooglePlay color="white" size="2em" />
            <p className="text-white-10 ">
              <span className="opacity-75">
                Get on the <br />
              </span>
              App Store
            </p>
          </a>
        </div>
        <p className="text-center md:text-left">
          © 2023 Build bank. All rights reserved.
        </p>
      </div> */}
    </div>
  );
};

export default Footer;
